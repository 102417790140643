$ = jQuery;

jQuery(function ($) {
    /* Enable "scrollh" event jQuery plugin */
    (function ($) {
        $.fn.enableHScroll = function () {
            function handler(el) {
                var lastPos = el
                    .on("scroll", function () {
                        var newPos = $(this).scrollLeft();
                        if (newPos !== lastPos) {
                            $(this).trigger("scrollh", newPos - lastPos);
                            lastPos = newPos;
                        }
                    })
                    .scrollLeft();
            }
            return this.each(function () {
                var el = $(this);
                if (!el.data("hScrollEnabled")) {
                    el.data("hScrollEnabled", true);
                    handler(el);
                }
            });
        };
    })(jQuery);

    // scroll event for timeline
    var lastScrollLeft = 0;
    $(".timeline-container").on("scroll", function (obj, offset) {
        $(".timeline-nav").removeClass("active");
        $(".timeline-arrow").removeClass("active");
        activateArrow();
    });

    // click event for tabs
    $(".timeline-nav").on("click", function (e) {
        e.preventDefault();
        $(".timeline-nav").removeClass("active");
        $(this).addClass("active");
        // calculate where we should scroll to
        var place = $(this).attr("data-num");
        var windowWidth = $(window).width();
        var placePos = windowWidth * place;
        // do horizontal scroll
        $(".timeline-container").animate(
            {
                scrollLeft: placePos,
            },
            100
        );
    });

    // mobile accordion functionality
    $(".timeline-card").first().show("fast");
    $(".timeline-accordion").on("click", function (e) {
        e.preventDefault();
        // undo current stuff
        $(".timeline-accordion").removeClass("active");
        $(".timeline-card").hide("fast");
        // set new stuff
        $(this).addClass("active");
        var panelHeight = $(this).next(".timeline-card").prop("scrollHeight");
        var activePanel = $(this).attr("data-panel");
        $(this)
            .next(".timeline-card")
            .show("fast", function () {
                $("html, body").animate(
                    {
                        scrollTop: $(activePanel).offset().top,
                    },
                    200
                );
            });
    });

    // activate correct arrow based on active container
    function activateArrow() {
        setTimeout(function () {
            var documentScrollLeft = $(".timeline-container").scrollLeft();
            var windowWidth = $(window).width();

            if (lastScrollLeft != documentScrollLeft) {
                lastScrollLeft = documentScrollLeft;
            }
            if (documentScrollLeft == 0) {
                $(".timeline-nav").first().addClass("active");
                $(".timeline-nav")
                    .first()
                    .next(".timeline-arrow")
                    .addClass("active");
            } else if (
                windowWidth <= documentScrollLeft &&
                documentScrollLeft < windowWidth * 2
            ) {
                $(".timeline-header > div")
                    .eq(1)
                    .find(".timeline-nav")
                    .addClass("active");
                $(".timeline-header > div")
                    .eq(1)
                    .find(".timeline-arrow")
                    .addClass("active");
            } else if (
                windowWidth * 2 <= documentScrollLeft &&
                documentScrollLeft < windowWidth * 3
            ) {
                $(".timeline-header > div")
                    .eq(2)
                    .find(".timeline-nav")
                    .addClass("active");
                $(".timeline-header > div")
                    .eq(2)
                    .find(".timeline-arrow")
                    .addClass("active");
            } else if (
                windowWidth * 3 <= documentScrollLeft &&
                documentScrollLeft < windowWidth * 4
            ) {
                $(".timeline-header > div")
                    .eq(3)
                    .find(".timeline-nav")
                    .addClass("active");
                $(".timeline-header > div")
                    .eq(3)
                    .find(".timeline-arrow")
                    .addClass("active");
            } else if (
                windowWidth * 4 <= documentScrollLeft &&
                documentScrollLeft < windowWidth * 5
            ) {
                $(".timeline-header > div")
                    .eq(4)
                    .find(".timeline-nav")
                    .addClass("active");
                $(".timeline-header > div")
                    .eq(4)
                    .find(".timeline-arrow")
                    .addClass("active");
            } else if (
                windowWidth * 5 <= documentScrollLeft &&
                documentScrollLeft < windowWidth * 6
            ) {
                $(".timeline-header > div")
                    .eq(5)
                    .find(".timeline-nav")
                    .addClass("active");
                $(".timeline-header > div")
                    .eq(5)
                    .find(".timeline-arrow")
                    .addClass("active");
            } else if (documentScrollLeft >= windowWidth * 6) {
                $(".timeline-header > div")
                    .eq(6)
                    .find(".timeline-nav")
                    .addClass("active");
                $(".timeline-header > div")
                    .eq(6)
                    .find(".timeline-arrow")
                    .addClass("active");
            }
        }, 1000);
    }
});
